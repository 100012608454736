export const config={
app:{
    host:"https://bonbon.dev",
    port: 3000
},
api:{
    host:"https://bonbon.dev/api",
    port: 3000
},
kvk:{
    host:"https://bonbon.dev/kvk",
    port: 3000
},
payments:{
    host:"https://bonbon.dev/payments",
    port: 3000
},
ws:{
    host:"wss://bonbon.dev/ws",
    port: 3001
},
webpack: {
    host: "http://localhost:8080",
    port: 8080
}
}
